(function() {
	var logo, container, dynamicLogoImg, fixedLogoImg, getMaxWidth, minWidth, getScrollTop, imgContainer, offset;

	container = $('#logo');
	imgContainer = $('.c-logo__container').not('.c-logo__container--push');
	logo = $('#logo .c-logo__img-outer');
	dynamicLogoImg = $('#logo .c-logo__img');
	fixedLogoImg = $('.c-logo__img--fixed');
	push = $('.c-logo__container--push');
	offset = 65;
	minWidth = 200;

	if (container.length) {
		getMaxSizes = function() {
			return {
				width: container.width(),
				height: parseFloat(container.css('padding-bottom'))
			};
		};

		getScrollTop = function() {
			var s = $(window).scrollTop() - push.offset().top + offset;

			return s < 0 ? 0 : s;
		};

		setWidth = function() {
			var ratio, max, height, width;

			max = getMaxSizes();
			ratio = max.width / max.height;
			height = max.height - getScrollTop();
			width = Math.round(height * ratio);

			if (width > minWidth) {
				logo.css({
					'width': Math.round(width) + 'px',
					'opacity': '1'
				});

				fixedLogoImg.addClass('c-logo__img--hidden');
				dynamicLogoImg.removeClass('c-logo__img--hidden');
			} else {
				logo.css({
					'width': minWidth + 'px'
				});

				fixedLogoImg.removeClass('c-logo__img--hidden');
				dynamicLogoImg.addClass('c-logo__img--hidden');
			}
		};

		$(window).on('load resize scroll', function() {
			setWidth();

			if(getScrollTop() > 0) {
				imgContainer.addClass('c-logo__container--fixed');
			} else {
				imgContainer.removeClass('c-logo__container--fixed');
			}
		});
	}
})();