(function() {
	var o, filter, currentFilter,
			filterBtn = $('#filter .c-filter__item a'),
			tiles = $('#l-grid .c-tile'),
			grid = $('#l-grid');

	grid.isotope();

	filter = function(filterClass, btn) {
		var self = this;

		if(currentFilter !== filterClass) {
			grid.isotope({filter: '.' + filterClass});
			filterBtn.removeClass('is-active');
			$('.' + filterClass).addClass('is-active');
			btn.addClass('is-active');
		} else {
			grid.isotope({filter: '*'});
			filterBtn.removeClass('is-active');
		}

		currentFilter = filterClass;
	};

	filterBtn.on('click', function(e) {
		e.preventDefault();

		var filterClass = $(this).data('filterclass');

		filter(filterClass, $(this));
	});

	if(!Helpers.isTouch()) {
		$('#l-grid .c-tile').not('.c-tile--tekst-verhaal').children('.c-tile__inner').each(function() {
			o = $.opener({
				'btn': $(this),
				'openEvent': 'mouseenter',
				'closeEvent': 'mouseleave',
				'target': $(this),
				'onOpen': function() {
					var html;

					html = '<div class="c-background-image"><img sizes="120vw" ';
					html += 'src="' + this.btn.data('src') + '"';
					html += 'srcset="' + this.btn.data('srcset') + '"';
					html += '" /></div>';

					this.btn.parent().addClass('open');

					grid.prepend(html);
				},

				'onClose': function() {
					this.btn.parent().removeClass('open');
					grid.find('.c-background-image').remove();
					grid.find('.c-bg-description').remove();
				}
			});
		});
	}
})();