(function() {
	var images = $('.c-roulette__img'),
			active = 0,
			activeClass = 'c-roulette__img--active', 
			setActiveImage;

	setActiveImage = function(i, iPrev) {
		images.eq(i).addClass(activeClass);
		images.eq(iPrev).removeClass(activeClass);
	};

	$(document).ready(function() {
		if (images.length && images.length > 1) {
			setInterval(function() {
				prevActive = active;
				active = active + 1 < images.length ? active + 1 : 0;

				setActiveImage(active, prevActive);
			}, 1000);
		}
	});
})();