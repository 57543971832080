(function() {
	var resizeContainer, init, fullscreen, textIsRelative, bindArrowEvents, closeFullscreen, offsetContainer, containerIsOffset, updateCaption, updatePagination, positionTextSlide, setSlideshowMargin,
			isFullscreen 	= false,
			textSlide = $('.c-slide--text'),
			slideshow 		= $('#slideshow'),
			slide 				= $('#slideshow .c-slide'),
			container 		= $('.l-slideshow'),
			nextArrow			= $('#next'),
			prevArrow 		= $('#previous'),
			startBtn			= $('#start'),
			closeBtn 			= $('#close'),
			caption 			= $('#caption'),
			pagination 		= $('#pagination'),
			margin 				= 94 + 94;

	textIsRelative = function() {
		return $('.c-slide--text').css('position') === 'relative' ? true : false;
	};

	setSlideshowMargin = function() {
		var extraMargin = 150,
				slideHeight = textSlide.outerHeight(),
				containerHeight = container.height();
		
		if (slideHeight + extraMargin > containerHeight && !textIsRelative()) {
			container.css('margin-bottom', slideHeight - containerHeight + extraMargin + 'px');
		} else {
			container.css('margin-bottom', '');
		}
	};

	offsetContainer = function() {
		var offset, imgOffset,
				firstSlide = slide.not('.c-slide--text').first();

		textSlide.css({
			'opacity': 1
		});

		imgOffset = firstSlide.offset().left;
		offset = textSlide.offset().left + textSlide.width() - imgOffset + (margin / 2);

		slideshow.css('transform', 'translate3d(' + offset + 'px,0,0)');

		prevArrow.removeClass('is-visible');
		caption.removeClass('is-visible');
		pagination.removeClass('is-visible');

		slideshow.slick('slickSetOption', 'arrows', false, true);

		containerIsOffset = true;
	};

	repositionContainer = function() {
		slideshow.css('transform', 'translate3d(0,0,0)');

		prevArrow.addClass('is-visible');
		caption.addClass('is-visible');
		pagination.addClass('is-visible');

		slideshow.slick('slickSetOption', 'arrows', true, true);
		// slideshow.slick('slickGoTo', 0, true);

		if (textIsRelative()) {
			textSlide.css({
				'opacity': 1
			});
		} else {
			textSlide.css({
				'opacity': 0
			});
		}

		containerIsOffset = false;
	};

	resizeContainer = function(container) {
		container.height($(window).height());
	};

	resizeImages = function(slides, height) {
		slides.not('.c-slide--text').each(function() {
			var ratio, imgOffset,
					img = $(this).find('img'),
					w = parseInt(img.attr('width')),
					h = parseInt(img.attr('height')),
					padding = parseInt(img.parent().css('padding-left')) * 2;;

			ratio = w / h;

			h = height - margin;
			w = h * ratio;

			if(w > ($(window).width() - padding)) {	

				w = $(window).width() - padding;
				h = w / ratio;
			}

			imgOffset = (height - h) / 2;
			img.css('margin-top', imgOffset + 'px');

			img.width(w);
			img.height(h);
		});
	};

	fullscreen = function() {
		var currentSlide;

		repositionContainer();

		$('.c-slide--text').addClass('is-hidden');

		container.addClass('is-fullscreen');

		closeBtn.removeClass('is-hidden');

		currentSlide = slideshow.slick('slickCurrentSlide');

		isFullscreen = true;
	};

	positionTextSlide = function() {
		var windowWidth = $(window).width();

		if (windowWidth > 1440) {
			textSlide.css('left', ((windowWidth - 1440) / 2) + 'px');
		} else {
			textSlide.css('left', '0');
		}
	};

	closeFullscreen = function() {
		$('.c-slide--text').removeClass('is-hidden');

		closeBtn.addClass('is-hidden');

		container.removeClass('is-fullscreen');

		isFullscreen = false;
	};

	updateCaption = function() {
		var currentSlide = $('.slick-current'),
				captionContent = currentSlide.children('img').data('caption');

		caption.empty();
		caption.append(captionContent);
	};

	updatePagination = function() {
		var currentSlide = slideshow.slick('slickCurrentSlide') + 1,
				pagEle = pagination.children('span');

		pagEle.empty();
		pagEle.append(currentSlide);
	};

	init = function() {
		resizeContainer(container);
		resizeImages(slide, container.height());
		positionTextSlide();

		slideshow.slick({
			slidesToShow:		1,
			slidesToScroll:	1,
			variableWidth:	true,
			adaptiveHeight: true,
			infinite: 			false,
			lazyLoad: 			'ondemand',
			centerMode:			true,
			arrows:					false,
			slide:					'.c-slide',
			prevArrow: 			prevArrow,
			nextArrow:			nextArrow
		});

		if(!textIsRelative()) offsetContainer();

		setSlideshowMargin();

		slideshow.on('afterChange', function(event, slick, currentSlide) {
			updateCaption();
			updatePagination();
		});

		slide.not('.c-slide--text').on('click', function() {
			fullscreen();
		});

		closeBtn.on('click', function() {
			closeFullscreen();
		});

		prevArrow.on('click', function() {
			if (slideshow.slick('slickCurrentSlide') === 0 && !textIsRelative()) {
				offsetContainer();
				nextArrow.addClass('is-disabled');
				startBtn.removeClass('is-disabled');

				if(isFullscreen) {
					closeFullscreen();
				}
			}
		});

		startBtn.on('click', function() {
				repositionContainer();
				nextArrow.removeClass('is-disabled');
				startBtn.addClass('is-disabled');
		});
	};

	$(document).ready(function() {
		if($('#slideshow')[0]){
			init();
		}
	});

	$(window).on('resize', function() {
		if($('#slideshow')[0]){
			positionTextSlide();

			if(containerIsOffset && !textIsRelative()) {
				offsetContainer();
			}

			if(textIsRelative()) {
				repositionContainer();
			}

			resizeContainer(container);
			resizeImages(slide, container.height());
			setSlideshowMargin();
		}
	});
})();